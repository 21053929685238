import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './style';
import { withStyles } from '@material-ui/styles';
import CoolPerks from './CoolPerks';
import WhatElse from './WhatElse';

type Props = {
  classes: Object,
  options: [],
};

const FirstTabPanel = ({ classes }: Props) => (
  <>
    <Typography
      className={classes.subHeaderContainer}
      variant='h3'
      color='textPrimary'>
      Senior React Native Developer
    </Typography>
    <Typography className={classes.textContainer}>
      Spark Labs is a team of entrepreneurs and coders that develops innovative
      websites and apps. In our unique structure, we spend approximately 70% of
      the time on contract work for clients, and the remaining 30% building out
      our own concept and ideas.
    </Typography>
    <Typography className={classes.textContainer}>
      We are currently looking to staff Sr React Native Developers.
    </Typography>
    <strong className={classes.textContainer}>What you’ll be working on</strong>
    <Typography className={classes.textContainer}>
      You will work with a vast amount of technology stacks, our mainstays are
      JavaScript (React/React Native, Node.JS, Serverless). Generally, you will
      be working as part of a team on various projects including apps (React
      Native), websites (React) and API’s (Node.JS / Serverless). In addition to
      working on a team, you may have solo projects that you would be assigned
      to complete from start to finish.
    </Typography>

    <Typography className={classes.textContainer}>
      Tools we use include Asana for project tracking, Slack for team
      communication, Github for source control and Everhour for time keeping.
    </Typography>

    {/* Requirements Section */}
    <strong className={classes.textContainer}>Requirements</strong>
    <ul className={classes.ul}>
      <li>
        React Native developer with prior experience building apps from scratch…
        aka you can build an app by yourself if needed.
      </li>
      <li>
        Experience building React Native native modules for android (Java /
        Kotlin) and ios (Swift / Objective C).
      </li>
      <li>
        Knowledgeable or able to work with/understand:
        <ul>
          <li>React Native</li>
          <li>JavaScript / TypeScript</li>
          <li>Android / Kotlin (Android)</li>
          <li>Swift / Objective C (iOS)</li>
          <li>AWS IoT / RTOS</li>
          <li>
            Bluetooth
            <ul>
              <li>
                Connection management to the device using Bluetooth Low Energy
                (BLE), as well as over Bluetooth Classic.
              </li>
              <li>Handling multiple BLE devices at a single time.</li>
              <li>
                Sending a command to the firmware calibration and get signal
                back on success/failure.
              </li>
              <li>
                Utilizing data from a device (e.g. heart rate, steps, other
                biometrics) into an app or web app interface
              </li>
              <li>
                Strong understanding reading buffers and interpreting sensor
                data (i.e. readInt16LE)
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    {/* Who You Are Section */}
    <strong className={classes.textContainer}>Who You Are</strong>
    <ul className={classes.ul}>
      <li>
        A true entrepreneur, always trying to learn more, and expand your
        technical skills.
      </li>
      <li>
        Knowledgeable in other programming languages; we often have
        opportunities with Java, Swift, Kotlin, JavaScript, Python, PHP and
        others.
      </li>
      <li>
        A great communicator and prior experience using project management tools
        such as JIRA and/or Asana.
      </li>
      <li>
        Fun to be around and willing to go the extra mile to deliver awesome
        products!
      </li>
    </ul>
    <CoolPerks />
    <WhatElse />
  </>
);

export default withStyles(styles)(FirstTabPanel);
