import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './style';
import { withStyles } from '@material-ui/styles';
import CoolPerks from './CoolPerks';
import WhatElse from './WhatElse';

type Props = {
  classes: Object,
  options: [],
};

const SecondTabPanel = ({ classes }: Props) => (
  <>
    <Typography
      className={classes.subHeaderContainer}
      variant='h3'
      color='textPrimary'>
      Senior Back End Developer
    </Typography>
    <Typography className={classes.textContainer}>
      Spark Labs is a team of entrepreneurs and coders that develops innovative
      websites and apps. In our unique structure, we spend approximately 70% of
      the time on contract work for clients, and the remaining 30% building out
      our own concept and ideas.
    </Typography>
    <Typography className={classes.textContainer}>
      We are currently looking to staff a Senior Back End Developer.
    </Typography>

    <strong className={classes.textContainer}>What you’ll be working on</strong>
    <Typography className={classes.textContainer}>
      You will work with a vast amount of technology stacks, our mainstays are
      leveraging AWS infrastructure and JavaScript. Generally, you will be
      working as part of a team on various projects including API’s (Serverless
      / Amplify), database architecture, apps (React Native), websites (React /
      Material-UI). In addition to working on a team, you may have solo projects
      that you would be assigned to complete from start to finish.
    </Typography>
    <Typography className={classes.textContainer}>
      Tools we use include Asana for project tracking, Slack for team
      communication, Github for source control and Everhour for time keeping.
    </Typography>

    {/* Requirements Section */}
    <strong className={classes.textContainer}>Requirements</strong>
    <ul className={classes.ul}>
      <li>
        Full stack engineer with prior experience building apps from scratch…
        aka you can build an app by yourself if needed.
      </li>
      <li>
        Knowledgeable or able to work with/understand:
        <ul>
          <li>
            AWS
            <ul>
              <li>Amplify</li>
              <li>API Gateway</li>
              <li>CloudFormation</li>
              <li>CloudFront</li>
              <li>CloudWatch</li>
              <li>EC2</li>
              <li>Lambda</li>
              <li>RDS</li>
              <li>S3</li>
              <li>SES</li>
              <li>SNS</li>
              <li>SQS</li>
            </ul>
          </li>
          <li>Linux (Ubuntu distribution preferred)</li>
          <li>
            Programming Languages
            <ul>
              <li>Bash</li>
              <li>JavaScript (Node.JS, TypeScript, Serverless, React)</li>
              <li>PHP</li>
              <li>Python</li>
            </ul>
          </li>
          <li>
            Databases
            <ul>
              <li>DynamoDB</li>
              <li>MongoDB</li>
              <li>MySQL</li>
              <li>Postgres</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    {/* Who You Are Section */}
    <strong className={classes.textContainer}>Who You Are</strong>
    <ul className={classes.ul}>
      <li>
        A true entrepreneur, always trying to learn more, and expand your
        technical skills.
      </li>
      <li>
        Polyglot programmer, understanding of multiple languages and stacks as
        we often have projects that come in various programming languages and
        forms.
      </li>
      <li>
        A great communicator and prior experience using project management tools
        such as JIRA and/or Asana.
      </li>
      <li>
        Fun to be around and willing to go the extra mile to deliver awesome
        products!
      </li>
    </ul>
    <CoolPerks />
    <WhatElse />
  </>
);

export default withStyles(styles)(SecondTabPanel);
