import React from 'react';
import Logo from 'images/sparklabs-logo.png';
import { makeStyles } from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: '15em',
    marginBottom: 100,
  },
  typography: {
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: '36px',
    marginRight: '2em',
  },
  logo: {
    marginTop: '1em',
    marginLeft: '8em',
    marginBottom: '2em',
    textAlign: 'left',
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <header className={classes.container} id="#top">
      <Grid container xs={12} className={classes.logo} direction='row' >
          <img src={Logo} alt="logo" id='top' />
      </Grid>
    </header>
  );
}
