import React from 'react';
import styles from './style';
import { withStyles } from '@material-ui/styles';

type Props = {
  classes: Object,
  options: [],
};

const WhatElse = ({ classes }: Props) => (
  <>
    <strong className={classes.textContainer}>What else?</strong>
    <ul className={classes.ul}>
      <li>
        This is a full time role and we prefer to hire as a 1099 contractor.
      </li>
      <li>Pay will be discussed according to experience level.</li>
    </ul>
  </>
);

export default withStyles(styles)(WhatElse);
