const styles = ({ palette, typography }) => ({
  ul: {
    marginTop: 5,
  },
  textContainer: {
    padding: `${typography.pxToRem(5)} ${typography.pxToRem(
      8
    )} ${typography.pxToRem(13)}`,
  },
  subHeaderContainer: {
    padding: `${typography.pxToRem(5)} ${typography.pxToRem(
      8
    )} ${typography.pxToRem(13)}`,
  },
});

export default styles;
