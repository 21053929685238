const styles = ({ typography }) => ({
  container: {
    padding: `${typography.pxToRem(10)} 0`,
  },
  link: {
    fontFamily: 'Work Sans',
    fontSize: 16,
    color: '#17252A',
    textTransform: 'none',
    textDecoration: 'none',
  },
});

export default styles;
