import React from 'react';
import styles from './style';
import { withStyles } from '@material-ui/styles';

type Props = {
  classes: Object,
  options: [],
};

const CoolPerks = ({ classes }: Props) => (
  <>
    <strong className={classes.textContainer}>Cool Perks</strong>
    <ul className={classes.ul}>
      <li>
        Working with a team of fun entrepreneurs that like to build cool stuff.
      </li>
      <li>
        Exposure to both the start-up world and Fortune 500 companies that we
        work with.
      </li>
      <li>Traveling to cool places for client work.</li>
      <li>
        Remote work up to 80% of the time with onsite meetings in a modern,
        co-working environment.
      </li>
      <li>
        Learn from people on the team that have built and sold software
        business.
      </li>
    </ul>
  </>
);

export default withStyles(styles)(CoolPerks);
