import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link } from 'gatsby';

import styles from './style';

type Props = {
  classes: Object,
  data: Object,
};

const JobPost = ({ classes, job }: Props) => {
  console.log('in JobPost: job is:', job);
  return (
    <div className={classes.container}>
      <Link
        state={{ choice: job }}
        className={classes.link}
        to='/open-position/'>
        <strong>{job.jobTitle}</strong>
      </Link>
    </div>
  );
};

export default withStyles(styles)(JobPost);
